<template>
	<div>
		<div id="firebaseui-auth-container"></div>
		{{ info }}
	</div>
</template>

<script>
import firebase from '@firebase/app'
import * as firebaseui from 'firebaseui'

export default {
	data() {
		return {
			info: ''
		}
	},
	mounted() {
		this.doThing()
	},
	metaInfo() {
		return {
			title: 'Login'
		}
	},
	methods: {
		signIn() {
			this.$store.dispatch('auth/login', { email: this.email, password: this.password }).then(() => {
				this.$store.dispatch('auth/checkUserStatus').then(() => {
					this.$router.push({ name: 'account' })
				})
			})
		},
		doThing() {
			//

			let ui = firebaseui.auth.AuthUI.getInstance()
			if (!ui) {
				ui = new firebaseui.auth.AuthUI(firebase.auth())
			}
			var uiConfig = {
				callbacks: {
					signInSuccessWithAuthResult: (authResult) => {
						if (authResult.user) {
							this.$store.dispatch('auth/checkUserStatus').then(() => {
								this.$router.push({ name: 'account' })
							})
						}
						if (authResult.additionalUserInfo) {
							this.info = authResult.additionalUserInfo.isNewUser ? 'New User' : 'Existing User'
						}
						// Do not redirect.
						return false
					},
					uiShown: function() {
						// The widget is rendered.
						// Hide the loader.
						// document.getElementById('loader').style.display = 'none'
					}
				},
				signInFlow: 'popup',
				signInSuccessUrl: 'account',
				signInOptions: [
					// Leave the lines as is for the providers you want to offer your users.
					firebase.auth.GoogleAuthProvider.PROVIDER_ID,
					firebase.auth.FacebookAuthProvider.PROVIDER_ID,
					firebase.auth.TwitterAuthProvider.PROVIDER_ID,
					firebase.auth.GithubAuthProvider.PROVIDER_ID,
					firebase.auth.EmailAuthProvider.PROVIDER_ID,
					firebase.auth.PhoneAuthProvider.PROVIDER_ID
					// firebaseui.auth.AnonymousAuthProvider.PROVIDER_ID
				]
			}
			//	var provider = firebase.auth.GoogleAuthProvider()
			//	firebase.auth().signInWithPopup(provider)

			ui.start('#firebaseui-auth-container', uiConfig)
		}
	}
}
</script>

<style>
.form-elegant .font-small {
	font-size: 0.8rem;
}

.form-elegant .z-depth-1a {
	-webkit-box-shadow: 0 2px 5px 0 rgba(55, 161, 255, 0.26), 0 4px 12px 0 rgba(121, 155, 254, 0.25);
	box-shadow: 0 2px 5px 0 rgba(55, 161, 255, 0.26), 0 4px 12px 0 rgba(121, 155, 254, 0.25);
}

.form-elegant .z-depth-1-half,
.form-elegant .btn:hover {
	-webkit-box-shadow: 0 5px 11px 0 rgba(85, 182, 255, 0.28), 0 4px 15px 0 rgba(36, 133, 255, 0.15);
	box-shadow: 0 5px 11px 0 rgba(85, 182, 255, 0.28), 0 4px 15px 0 rgba(36, 133, 255, 0.15);
}
</style>
